import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SEOParams } from '@app/core/interfaces/seo.interface';
import { Lang } from '@app/core/types/lang.types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  private readonly siteName = 'Tripilog';

  constructor(
    private title: Title,
    private meta: Meta,
  ) {}

  set(params: SEOParams, currentLang: Lang): void {
    const { title, description, path, imageUrl } = params;

    this.removeAllMetaTags();
    this.setTitle(title);
    this.setDescription(description);
    this.setUrl(path);
    this.setImageUrl(imageUrl);
    this.setLocale(currentLang);
    this.setKeywords(currentLang);
  }

  private setTitle(title: string): void {
    const titleContent = title ? `${title} | ${this.siteName}` : this.siteName;

    this.title.setTitle(titleContent);
    this.meta.addTag({ property: 'og:title', content: titleContent });
    this.meta.addTag({ name: 'twitter:title', content: titleContent });
    this.meta.addTag({ name: 'DC.title', content: titleContent });
  }

  private setDescription(description: string): void {
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ name: 'DC.description', content: description });
  }

  private setUrl(path: string): void {
    this.meta.addTag({ property: 'og:url', content: environment.appUrl + path });
  }

  private setImageUrl(imageUrl: string): void {
    this.meta.addTag({ property: 'og:image', content: imageUrl });
    this.meta.addTag({ name: 'twitter:image', content: imageUrl });
  }

  private setLocale(lang: Lang): void {
    const locale = lang === 'es' ? 'es_ES' : 'en_US';
    this.meta.addTag({ property: 'og:locale', content: locale });
    this.meta.addTag({ name: 'language', content: lang });
    this.meta.addTag({ name: 'DC.language', content: lang });
  }

  private setKeywords(lang: Lang): void {
    const keywords =
      lang === 'es'
        ? 'viajes, relatos de viaje, conecta con viajeros, herramientas de viaje, destinos únicos'
        : 'travel, travel stories, connect with travelers, travel tools, unique destinations';

    this.meta.addTag({ name: 'keywords', content: keywords });
  }

  private removeAllMetaTags(): void {
    const tags = [
      'name="title"',
      'name="description"',
      'property="og:title"',
      'property="og:description"',
      'property="og:url"',
      'property="og:image"',
      'name="twitter:title"',
      'name="twitter:description"',
      'name="twitter:image"',
      'property="og:locale"',
      'name="keywords"',
      'name="language"',
    ];

    tags.forEach((elem) => {
      this.meta.removeTag(elem);
    });
  }
}
